import React, { Component } from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import { useTable } from "react-table"
import {
  Label,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import { CAge } from "../components/WebMapView/liveness"

interface LivenessGroup {
  node: {
    avg_10: number
    avg_20: number
    avg_30: number
    avg_40: number
    avg_50: number
    avg_60: number
    avg_70?: number
    avg_80?: number
    is_name: string
    max_10: number
    max_20: number
    max_30: number
    max_40: number
    max_50: number
    max_60: number
    max_70?: number
    max_80?: number
    min_10: number
    min_20: number
    min_30: number
    min_40: number
    min_50: number
    min_60: number
    min_70?: number
    min_80?: number
    uk_name: string
  }
}

interface LivenessPageProps {
  data: {
    allCXlsxTotal: {
      edges: CAge[]
    }
    allLivenessGroupsXlsxAo: {
      edges: LivenessGroup[]
    }
    allLivenessGroupsXlsxSg: {
      edges: LivenessGroup[]
    }
    allLivenessGroupsXlsxSf: {
      edges: LivenessGroup[]
    }
    allLivenessGroupsXlsxRl: {
      edges: LivenessGroup[]
    }
    allLivenessGroupsXlsxIb: {
      edges: LivenessGroup[]
    }
  }
}

export const pageQuery = graphql`
  {
    allCXlsxTotal {
      edges {
        node {
          Po0
          Po2
          Po_2
          SS0
          SS2_4
          SS6_10
          Lp0
          Lp2
          Lp4
          RL4
          RL8
          RL12
          RL16
          IB5
          IB8
          age
        }
      }
    }
    allLivenessGroupsXlsxAo {
      edges {
        node {
          avg_10
          avg_20
          avg_30
          avg_40
          avg_50
          avg_60
          is_name
          max_10
          max_20
          max_30
          max_40
          max_50
          max_60
          min_10
          min_20
          min_30
          min_40
          min_50
          min_60
          uk_name
        }
      }
    }
    allLivenessGroupsXlsxSg {
      edges {
        node {
          avg_10
          avg_20
          avg_30
          avg_40
          avg_50
          avg_60
          avg_70
          avg_80
          is_name
          max_10
          max_20
          max_30
          max_40
          max_50
          max_60
          max_70
          max_80
          min_10
          min_20
          min_30
          min_40
          min_50
          min_60
          min_70
          min_80
          uk_name
        }
      }
    }
    allLivenessGroupsXlsxSf {
      edges {
        node {
          avg_10
          avg_20
          avg_30
          avg_40
          avg_50
          avg_60
          is_name
          max_10
          max_20
          max_30
          max_40
          max_50
          max_60
          min_10
          min_20
          min_30
          min_40
          min_50
          min_60
          uk_name
        }
      }
    }
    allLivenessGroupsXlsxRl {
      edges {
        node {
          avg_10
          avg_20
          avg_30
          avg_40
          avg_50
          avg_60
          avg_70
          avg_80
          is_name
          max_10
          max_20
          max_30
          max_40
          max_50
          max_60
          max_70
          max_80
          min_10
          min_20
          min_30
          min_40
          min_50
          min_60
          min_70
          min_80
          uk_name
        }
      }
    }
    allLivenessGroupsXlsxIb {
      edges {
        node {
          avg_10
          avg_20
          avg_30
          avg_40
          avg_50
          avg_60
          is_name
          max_10
          max_20
          max_30
          max_40
          max_50
          max_60
          min_10
          min_20
          min_30
          min_40
          min_50
          min_60
          uk_name
        }
      }
    }
  }
`

export default class LivenessPage extends Component<LivenessPageProps> {
  public render() {
    const d = this.props.data.allCXlsxTotal.edges
    const dAO = this.props.data.allLivenessGroupsXlsxAo.edges
    const dSG = this.props.data.allLivenessGroupsXlsxSg.edges
    const dSF = this.props.data.allLivenessGroupsXlsxSf.edges
    const dRL = this.props.data.allLivenessGroupsXlsxRl.edges
    const dIB = this.props.data.allLivenessGroupsXlsxIb.edges
    return (
      <Layout>
        <SEO title="Gróska" />
        <h2>Gróska</h2>
        <p>
          Gróska er metin eftir yfirhæð og gróðursetningaraldri. Yfirhæð er
          hæðin á sverasta trénu á 100 fermetra svæði eða strangt til tekið
          meðalhæð 100 sverustu trjánna á hverjum hektara lands. Yfirhæð vex með
          aldri en þegar líða tekur á dregur úr yfirhæðarvextinum. Því meiri
          yfirhæð við sama aldur því hærra er gróskustigið. Gróskustig er
          flokkað í gróskuflokka og hér verða gróskuflokkarnir táknaðir með
          skammstöfun trjátegundanna og áætlaðri yfirhæð við 50 ára aldur (sjá
          íslensk heiti í töflunum hér fyrir neðan).
        </p>
        <p>
          Að öllu óbreyttu fer kolefnisbinding skógar eftir gróskustigi. Því
          hærra gróskustig því meiri er bindingin. Gerðir eru ferlar
          kolefnisforða (ofan- og neðanjarðar) og gróðursetningaraldurs. Út
          frá þessum ferlum er síðan reiknuð út árleg binding CO<sub>2</sub> í trjám frá 
          5 ára aldri til 50 ára aldurs. Þær stærðir eru síðan nýttar í
          Skógarkolefnisreikninn.
        </p>
        <h3>Alaskaösp</h3>
        <p>
          Gróskuflokkun alaskaaspar byggir ár gróskuflokkun hraðavaxta
          aspartegunda (e: poplar) á Bretlandi. Neðsti gróskuflokkurinn þar
          heitir Po4 og við hann er síðan bætt fjórum lakari íslenskum flokkum
          Po2, Po0, Po-2 og Po-4. Taflan hér fyrir neðan sýnir yfirhæð við
          mismunandi aldur:
        </p>
        <div>
          <table>
            <thead>
              <tr>
                <th rowSpan={2}>
                  Íslenskt
                  <br /> heiti
                </th>
                <th rowSpan={2}>
                  Enskt
                  <br />
                  heiti
                </th>
                <th rowSpan={2}></th>
                <th colSpan={6}>Aldur</th>
              </tr>
              <tr>
                <th>10</th>
                <th>20</th>
                <th>30</th>
                <th>40</th>
                <th>50</th>
                <th>60</th>
              </tr>
            </thead>
            <tbody>
              {dAO.map(({ node }) => {
                return (
                  <React.Fragment>
                    <tr>
                      <td rowSpan={3}>{node.is_name}</td>
                      <td rowSpan={3}>{node.uk_name}</td>
                      <td>Hæsta</td>
                      <td>{node.max_10}</td>
                      <td>{node.max_20}</td>
                      <td>{node.max_30}</td>
                      <td>{node.max_40}</td>
                      <td>{node.max_50}</td>
                      <td>{node.max_60}</td>
                    </tr>
                    <tr>
                      <td>Miðgildi</td>
                      <td>{node.avg_10}</td>
                      <td>{node.avg_20}</td>
                      <td>{node.avg_30}</td>
                      <td>{node.avg_40}</td>
                      <td>{node.avg_50}</td>
                      <td>{node.avg_60}</td>
                    </tr>
                    <tr>
                      <td>Lægsta</td>
                      <td>{node.min_10}</td>
                      <td>{node.min_20}</td>
                      <td>{node.min_30}</td>
                      <td>{node.min_40}</td>
                      <td>{node.min_50}</td>
                      <td>{node.min_60}</td>
                    </tr>
                  </React.Fragment>
                )
              })}
            </tbody>
          </table>
        </div>
        <p>
          Tekist hefur að búa til þrjá ferlakolefnisforða fyrir alaskaösp á
          Íslandi og það er fyrir gróskuflokkana AÖ9, AÖ16 og AÖ21 (sjá mynd hér
          fyrir neðan).
        </p>
        <div className="container">
          <h4>Alaskaösp</h4>
          <p>
            Samband gróðursetningaraldurs og kolefnisforða í trjám fyrir þrjá
            gróskuflokka
          </p>
          <LineChart
            width={500}
            height={300}
            data={d}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              type="number"
              tickCount={16}
              domain={[5, 60]}
              dataKey="node.age"
            >
              <Label position="insideBottomRight" offset={-10}>
                Aldur (ár)
              </Label>
            </XAxis>
            <YAxis>
              <Label
                angle={270}
                position="left"
                style={{ textAnchor: "middle", color: "gray" }}
              >
                Uppsöfnuð Kolefnisbinding (t/ha)
              </Label>
            </YAxis>
            <Tooltip
              wrapperStyle={{ opacity: 0.5 }}
              labelFormatter={function(value) {
                return `Aldur: ${value}`
              }}
              labelStyle={{ color: "gray" }}
            />
            <Legend />
            <Line
              name="AÖ21"
              type="monotone"
              dataKey="node.Po2"
              stroke="green"
              dot={false}
            />
            <Line
              name="AÖ16"
              type="monotone"
              dataKey="node.Po0"
              stroke="red"
              dot={false}
            />
            <Line
              name="AÖ9"
              type="monotone"
              dataKey="node.Po_2"
              stroke="blue"
              dot={false}
            />
          </LineChart>
        </div>
        <h3>Sitkagreni og sitkabastarður</h3>
        <p>
          Gróskuflokkun sitkagrenis og sitkabastarðs byggir á gróskuflokkun
          sitkagrenis á Bretlandi. Neðstu gróskuflokkarnir þar heita SS6 til
          SS10 og við þá er síðan bætt þremur lakari íslenskum flokkum SS0, SS2
          og SS4. Taflan hér fyrir neðan sýnir yfirhæð við mismunandi aldur:
        </p>
        <div>
          <table>
            <thead>
              <tr>
                <th rowSpan={2}>
                  Íslenskt
                  <br /> heiti
                </th>
                <th rowSpan={2}>
                  Enskt
                  <br />
                  heiti
                </th>
                <th rowSpan={2}></th>
                <th colSpan={8}>Aldur</th>
              </tr>
              <tr>
                <th>10</th>
                <th>20</th>
                <th>30</th>
                <th>40</th>
                <th>50</th>
                <th>60</th>
                <th>70</th>
                <th>80</th>
              </tr>
            </thead>
            <tbody>
              {dSG.map(({ node }) => {
                return (
                  <React.Fragment>
                    <tr>
                      <td rowSpan={3}>{node.is_name}</td>
                      <td rowSpan={3}>{node.uk_name}</td>
                      <td>Hæsta</td>
                      <td>{node.max_10}</td>
                      <td>{node.max_20}</td>
                      <td>{node.max_30}</td>
                      <td>{node.max_40}</td>
                      <td>{node.max_50}</td>
                      <td>{node.max_60}</td>
                      <td>{node.max_70}</td>
                      <td>{node.max_80}</td>
                    </tr>
                    <tr>
                      <td>Miðgildi</td>
                      <td>{node.avg_10}</td>
                      <td>{node.avg_20}</td>
                      <td>{node.avg_30}</td>
                      <td>{node.avg_40}</td>
                      <td>{node.avg_50}</td>
                      <td>{node.avg_60}</td>
                      <td>{node.avg_70}</td>
                      <td>{node.avg_80}</td>
                    </tr>
                    <tr>
                      <td>Lægsta</td>
                      <td>{node.min_10}</td>
                      <td>{node.min_20}</td>
                      <td>{node.min_30}</td>
                      <td>{node.min_40}</td>
                      <td>{node.min_50}</td>
                      <td>{node.min_60}</td>
                      <td>{node.min_70}</td>
                      <td>{node.min_80}</td>
                    </tr>
                  </React.Fragment>
                )
              })}
            </tbody>
          </table>
        </div>
        <p>
          Tekist hefur að búa til þrjá ferlakolefnisforða fyrir sitkagreni og
          sitkabastarð á Íslandi og það er einn ferill fyrir gróskuflokkinn SG5,
          einn sameiginlegur fyrir gróskuflokkana SG8 og SG11 og einn
          sameiginlegur fyrir SG14, SG16 og SG19 (sjá mynd hér fyrir neðan).{" "}
        </p>
        <div className="container">
          <h4>Sitkagreni og sitkabastarður</h4>
          <p>
            Samband gróðursetningaraldurs og kolefnisforða í trjám fyrir þrjá
            gróskuflokka
          </p>
          <LineChart
            width={500}
            height={300}
            data={d}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              type="number"
              tickCount={16}
              domain={[5, "dataMax"]}
              dataKey="node.age"
            >
              <Label position="insideBottomRight" offset={-10}>
                Aldur (ár)
              </Label>
            </XAxis>
            <YAxis>
              <Label
                angle={270}
                position="left"
                style={{ textAnchor: "middle" }}
              >
                Uppsöfnuð Kolefnisbinding (t/ha)
              </Label>
            </YAxis>
            <Tooltip
              wrapperStyle={{ opacity: 0.5 }}
              labelFormatter={function(value) {
                return `Aldur: ${value}`
              }}
            />
            <Legend />
            <Line
              name="SG14-19"
              type="monotone"
              dataKey="node.SS6_10"
              stroke="green"
              dot={false}
            />
            <Line
              name="SG8-11"
              type="monotone"
              dataKey="node.SS2_4"
              stroke="red"
              dot={false}
            />
            <Line
              name="SG5"
              type="monotone"
              dataKey="node.SS0"
              stroke="blue"
              dot={false}
            />
          </LineChart>
        </div>
        <h3>Stafafura</h3>
        <p>
          Gróskuflokkun stafafuru byggir á gróskuflokkun sömu tegundar á
          Bretlandi. Neðstu gróskuflokkarnir þar heita LP4 og LP6 og við þá er
          síðan bætt tveimur lakari íslenskum flokkum LP0 og LP2. Taflan hér
          fyrir neðan sýnir yfirhæð við mismunandi aldur:
        </p>
        <div>
          <table>
            <thead>
              <tr>
                <th rowSpan={2}>
                  Íslenskt
                  <br /> heiti
                </th>
                <th rowSpan={2}>
                  Enskt
                  <br />
                  heiti
                </th>
                <th rowSpan={2}></th>
                <th colSpan={6}>Aldur</th>
              </tr>
              <tr>
                <th>10</th>
                <th>20</th>
                <th>30</th>
                <th>40</th>
                <th>50</th>
                <th>60</th>
              </tr>
            </thead>
            <tbody>
              {dSF.map(({ node }) => {
                return (
                  <React.Fragment>
                    <tr>
                      <td rowSpan={3}>{node.is_name}</td>
                      <td rowSpan={3}>{node.uk_name}</td>
                      <td>Hæsta</td>
                      <td>{node.max_10}</td>
                      <td>{node.max_20}</td>
                      <td>{node.max_30}</td>
                      <td>{node.max_40}</td>
                      <td>{node.max_50}</td>
                      <td>{node.max_60}</td>
                    </tr>
                    <tr>
                      <td>Miðgildi</td>
                      <td>{node.avg_10}</td>
                      <td>{node.avg_20}</td>
                      <td>{node.avg_30}</td>
                      <td>{node.avg_40}</td>
                      <td>{node.avg_50}</td>
                      <td>{node.avg_60}</td>
                    </tr>
                    <tr>
                      <td>Lægsta</td>
                      <td>{node.min_10}</td>
                      <td>{node.min_20}</td>
                      <td>{node.min_30}</td>
                      <td>{node.min_40}</td>
                      <td>{node.min_50}</td>
                      <td>{node.min_60}</td>
                    </tr>
                  </React.Fragment>
                )
              })}
            </tbody>
          </table>
        </div>
        <p>
          Tekist hefur að búa til þrjá ferla kolefnisforða fyrir stafafuru á
          Íslandi og það er einn ferill fyrir gróskuflokkinn SF5, einn fyrir
          gróskuflokkinn SF10 og einn fyrir SF15 (sjá mynd hér fyrir neðan).{" "}
        </p>
        <div className="container">
          <h4>Stafafura</h4>
          <p>
            Samband gróðursetningaraldurs og kolefnisforða í trjám fyrir þrjá
            gróskuflokka
          </p>
          <LineChart
            width={500}
            height={300}
            data={d}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              type="number"
              tickCount={16}
              domain={[5, "dataMax"]}
              dataKey="node.age"
            >
              <Label position="insideBottomRight" offset={-10}>
                Aldur (ár)
              </Label>
            </XAxis>
            <YAxis>
              <Label
                angle={270}
                position="left"
                style={{ textAnchor: "middle" }}
              >
                Uppsöfnuð Kolefnisbinding (t/ha)
              </Label>
            </YAxis>
            <Tooltip
              wrapperStyle={{ opacity: 0.5 }}
              labelFormatter={function(value) {
                return `Aldur: ${value}`
              }}
            />
            <Legend />
            <Line
              name="SF15"
              type="monotone"
              dataKey="node.Lp4"
              stroke="green"
              dot={false}
            />
            <Line
              name="SF10"
              type="monotone"
              dataKey="node.Lp2"
              stroke="red"
              dot={false}
            />
            <Line
              name="SF5"
              type="monotone"
              dataKey="node.Lp0"
              stroke="blue"
              dot={false}
            />
          </LineChart>
        </div>
        <h3>Rússalerki</h3>
        <p>
          Til er gróskuflokkun fyrir rússalerki á Íslandi. Skilgreindir hafa
          verið 5 gróskuflokkar sem nefndir eru RL<sub>80</sub>5, RL<sub>80</sub>10,
           RL<sub>80</sub>15, RL<sub>80</sub>20 og RL<sub>80</sub>25. Tölurnar
           tákna yfirhæð við 80 ára aldur. Eins og áður eru íslensku heitin á
           gróskuflokkunum miðuð við yfirhæð við 50 ára aldur. Taflan hér fyrir
           neðan sýnir yfirhæð við mismunandi aldur:
        </p>
        <div>
          <table>
            <thead>
              <tr>
                <th rowSpan={2}>
                  Íslenskt
                  <br /> heiti
                </th>
                <th rowSpan={2}>
                  Íslenskt
                  <br />
                  nafn
                </th>
                <th rowSpan={2}></th>
                <th colSpan={8}>Aldur</th>
              </tr>
              <tr>
                <th>10</th>
                <th>20</th>
                <th>30</th>
                <th>40</th>
                <th>50</th>
                <th>60</th>
                <th>70</th>
                <th>80</th>
              </tr>
            </thead>
            <tbody>
              {dRL.map(({ node }) => {
                return (
                  <React.Fragment>
                    <tr>
                      <td rowSpan={3}>{node.is_name}</td>
                      <td rowSpan={3}>
                        <span
                          dangerouslySetInnerHTML={{ __html: node.uk_name }}
                        />
                      </td>
                      <td>Hæsta</td>
                      <td>{node.max_10}</td>
                      <td>{node.max_20}</td>
                      <td>{node.max_30}</td>
                      <td>{node.max_40}</td>
                      <td>{node.max_50}</td>
                      <td>{node.max_60}</td>
                      <td>{node.max_70}</td>
                      <td>{node.max_80}</td>
                    </tr>
                    <tr>
                      <td>Miðgildi</td>
                      <td>{node.avg_10}</td>
                      <td>{node.avg_20}</td>
                      <td>{node.avg_30}</td>
                      <td>{node.avg_40}</td>
                      <td>{node.avg_50}</td>
                      <td>{node.avg_60}</td>
                      <td>{node.avg_70}</td>
                      <td>{node.avg_80}</td>
                    </tr>
                    <tr>
                      <td>Lægsta</td>
                      <td>{node.min_10}</td>
                      <td>{node.min_20}</td>
                      <td>{node.min_30}</td>
                      <td>{node.min_40}</td>
                      <td>{node.min_50}</td>
                      <td>{node.min_60}</td>
                      <td>{node.min_70}</td>
                      <td>{node.min_80}</td>
                    </tr>
                  </React.Fragment>
                )
              })}
            </tbody>
          </table>
        </div>
        <p>
          Hér eru birtir fjórir ferlar kolefnisforða fyrir rússalerki á Íslandi,
          einn ferill fyrir hvern gróskuflokk að undanskildum RL20 sem kemur
          ekki fyrir í gróskuflokkamælingunum sem notaðar eru í greiningunni hér
          (Sjá mynd hér fyrir neðan).{" "}
        </p>
        <div className="container">
          <h4>Rússalerki</h4>
          <p>
            Samband gróðursetningaraldurs og kolefnisforða í trjám fyrir fjóra
            gróskuflokka
          </p>
          <LineChart
            width={500}
            height={300}
            data={d}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              type="number"
              tickCount={16}
              domain={[5, "dataMax"]}
              dataKey="node.age"
            >
              <Label position="insideBottomRight" offset={-10}>
                Aldur (ár)
              </Label>
            </XAxis>
            <YAxis>
              <Label
                angle={270}
                position="left"
                style={{ textAnchor: "middle" }}
              >
                Uppsöfnuð Kolefnisbinding (t/ha)
              </Label>
            </YAxis>
            <Tooltip
              wrapperStyle={{ opacity: 0.5 }}
              labelFormatter={function(value) {
                return `Aldur: ${value}`
              }}
            />
            <Legend />
            <Line
              name="RL16"
              type="monotone"
              dataKey="node.RL16"
              stroke="green"
              dot={false}
            />
            <Line
              name="RL12"
              type="monotone"
              dataKey="node.RL12"
              stroke="orange"
              dot={false}
            />
            <Line
              name="RL8"
              type="monotone"
              dataKey="node.RL8"
              stroke="red"
              dot={false}
            />
            <Line
              name="RL4"
              type="monotone"
              dataKey="node.RL4"
              stroke="blue"
              dot={false}
            />
          </LineChart>
        </div>
        <h3>Ilmbjörk</h3>
        <p>
          Stuðst var við gróskuflokkun í náttúrulegum birkiskógi á Hallormsstað
          þar sem skilgreindir voru þrír flokkar I, II og III með meðalhæð við
          60 ára brjósthæðaraldur 8,8 m, 6,65 m og undir 6,65 m. Samband
          yfirhæðar og meðalhæðar hjá ilmbjörk er að yfirhæð er 13,1 m, 9,2m og
          5,8 m við 60 ára gróðursetningaraldur fyrir flokkana þrjá. Taflan hér
          fyrir neðan sýnir yfirhæð við mismunandi aldur:
        </p>
        <div>
          <table>
            <thead>
              <tr>
                <th rowSpan={2}>
                  Íslenskt
                  <br /> nafn
                </th>
                <th rowSpan={2}>
                  Íslenskt
                  <br />
                  heiti
                </th>
                <th rowSpan={2}></th>
                <th colSpan={6}>Aldur</th>
              </tr>
              <tr>
                <th>10</th>
                <th>20</th>
                <th>30</th>
                <th>40</th>
                <th>50</th>
                <th>60</th>
              </tr>
            </thead>
            <tbody>
              {dIB.map(({ node }) => {
                return (
                  <React.Fragment>
                    <tr>
                      <td rowSpan={3}>{node.is_name}</td>
                      <td rowSpan={3}>{node.uk_name}</td>
                      <td>Hæsta</td>
                      <td>{node.max_10}</td>
                      <td>{node.max_20}</td>
                      <td>{node.max_30}</td>
                      <td>{node.max_40}</td>
                      <td>{node.max_50}</td>
                      <td>{node.max_60}</td>
                    </tr>
                    <tr>
                      <td>Miðgildi</td>
                      <td>{node.avg_10}</td>
                      <td>{node.avg_20}</td>
                      <td>{node.avg_30}</td>
                      <td>{node.avg_40}</td>
                      <td>{node.avg_50}</td>
                      <td>{node.avg_60}</td>
                    </tr>
                    <tr>
                      <td>Lægsta</td>
                      <td>{node.min_10}</td>
                      <td>{node.min_20}</td>
                      <td>{node.min_30}</td>
                      <td>{node.min_40}</td>
                      <td>{node.min_50}</td>
                      <td>{node.min_60}</td>
                    </tr>
                  </React.Fragment>
                )
              })}
            </tbody>
          </table>
        </div>
        <p>
          Einungis var unnt að gera tvo ferla kolefnisforða fyrir ilmbjörk fyrir
          gróskuflokka I og II saman og III sem hér eru nefndir IB8 og IB5.
          Mælingar fyrir vaxtarmesta gróskuflokkinn I sem hér er nefndur IB12 
          voru einungis 8 og höfðu lítil áhrif á IB8 ferilinn (Sjá mynd hér fyrir
          neðan).
        </p>
        <div className="container">
          <h4>Ilmbjörk</h4>
          <p>
            Samband gróðursetningaraldurs og kolefnisforða í trjám fyrir tvo
            gróskuflokka
          </p>
          <LineChart
            width={500}
            height={300}
            data={d}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              type="number"
              tickCount={16}
              domain={[5, "dataMax"]}
              dataKey="node.age"
            >
              <Label position="insideBottomRight" offset={-10}>
                Aldur (ár)
              </Label>
            </XAxis>
            <YAxis>
              <Label
                angle={270}
                position="left"
                style={{ textAnchor: "middle" }}
              >
                Uppsöfnuð Kolefnisbinding (t/ha)
              </Label>
            </YAxis>
            <Tooltip
              wrapperStyle={{ opacity: 0.5 }}
              labelFormatter={function(value) {
                return `Aldur: ${value}`
              }}
            />
            <Legend />
            <Line
              name="IB8"
              type="monotone"
              dataKey="node.IB8"
              stroke="green"
              dot={false}
            />
            <Line
              name="IB5"
              type="monotone"
              dataKey="node.IB5"
              stroke="blue"
              dot={false}
            />
          </LineChart>
        </div>
      </Layout>
    )
  }
}
